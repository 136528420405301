@use '@styles/theme.module' as *;

.wrapper {
  position: relative;
  align-self: center;
  width: 100%;
}

.error_icon {
  margin-right: spacing(1);
}

.error_msg {
  color: $errorFG;
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin-bottom: spacing(2);
}
